import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './HomePage';
import Projects from './Projects';
import Jobs from './Jobs'; 
import Contact from './Contact';
import About from './About';
import Application from './Application';
import ApplicationsList from './ApplicationsList';
import RatingsPage from './RatingsPage'; 

import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';

function App() {
  return (
    <Router>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/application" element={<Application/>} />
        <Route path="/applications" element={<ApplicationsList />} />
        <Route path="/ratings" element={<RatingsPage />} /> 
      </Routes>
    </Router>
  );
}

export default App;