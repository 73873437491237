import React, { useState, useEffect } from 'react';
import logo from './images/logo.png'; 
import { Link } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import './index.css';

const RatingsPage = () => {
    const [user, setUser] = useState(null);
    const [ratings, setRatings] = useState([]);
    const [hasRated, setHasRated] = useState(false); 
    const [showMenu, setShowMenu] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('Home');
    const [message, setMessage] = useState(''); 
    const [comment, setComment] = useState('');
    // Toggle hamburger menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Handle navigation link click
    const handleLinkClick = (link) => {
        setActiveLink(link);
        setMenuOpen(false); 
    };

    // Handle login success
    const handleLoginSuccess = (response) => {
        const { credential } = response;
        const decoded = decodeJwtResponse(credential);
        const userData = {
            name: decoded.name,
            email: decoded.email,
            imageUrl: decoded.picture,
        };
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('hasRated', false); 
    };

    // Decode Google JWT response
    const decodeJwtResponse = (credential) => {
        const base64Url = credential.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    };

    // Handle logout
    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user');
        setShowMenu(false); 
        setHasRated(false); 
    };

    const fetchRatings = () => {
        const savedRatings = JSON.parse(localStorage.getItem('ratings')) || [];
        setRatings(savedRatings);
    };

    // Submit rating
    const submitRating = (ratingValue) => {
        if (!user) {
            setMessage('Please login to rate.');
            return;
        }

        if (hasRated) {
            setMessage('You have already rated.');
            return;
        }

        const ratingData = {
            email: user.email,
            rating: ratingValue,
            comment: comment, 
            imageUrl: user.imageUrl,
        };

        // Send rating data to the PHP API
        fetch('https://hocompany.website/api/ratingsPage.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ratingData),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.status === 'success') {
                setMessage(data.message);  
                setRatings((prevRatings) => [
                    ...prevRatings,
                    ratingData
                ]);
                setHasRated(true);
                localStorage.setItem('hasRated', true);
                localStorage.setItem('ratings', JSON.stringify([...ratings, ratingData]));
            } else {
                setMessage(data.message); 
            }
        })
        .catch((error) => {
            console.error('Error submitting rating:', error);
            setMessage('There was an error submitting your rating. Please try again later.');
        });
    };

    // Render stars for rating
    const renderStars = (ratingValue) => {
        return Array.from({ length: 5 }, (_, index) => (
            <span
                key={index}
                className={`star ${index < ratingValue ? 'filled' : ''}`}
                onClick={() => submitRating(index + 1)}
            >
                ★
            </span>
        ));
    };

    // Load user and ratings from local storage on mount
    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem('user'));
        const hasUserRated = localStorage.getItem('hasRated') === 'true';
        if (savedUser) {
            setUser(savedUser);
        }
        setHasRated(hasUserRated);
        fetchRatings();
    }, []);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
            }, 3000); 

            return () => clearTimeout(timer); 
        }
    }, [message]);

    return (
        <div>
 {/* Header Section */}
 <header className="header">
  <div className="logo">
  <img src={logo} alt="Logo" />
  </div>

  {/* Hamburger menu icon */}
  <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
  <span></span>
  <span></span>
  <span></span>
  </div>

  {/* Navigation Menu */}
  <ul className={`menu ${menuOpen ? 'open' : ''}`}>
  <li>
  <Link to="/" className={activeLink === 'Home' ? 'active' : ''} onClick={() => handleLinkClick('Home')}>
  Home
  </Link>
  </li>
  <li>
  <Link to="/projects" className={activeLink === 'Projects' ? 'active' : ''} onClick={() => handleLinkClick('Projects')}>
  Projects
  </Link>
  </li>
  <li>
  <Link to="/jobs" className={activeLink === 'Jobs' ? 'active' : ''} onClick={() => handleLinkClick('Jobs')}>
  Jobs
  </Link>
  </li>
  <li>
  <Link to="/contact" className={activeLink === 'Contact' ? 'active' : ''} onClick={() => handleLinkClick('Contact')}>
  Contact
  </Link>
  </li>
  <li>
  <Link to="/about" className={activeLink === 'About' ? 'active' : ''} onClick={() => handleLinkClick('About')}>
   About
  </Link>
  </li>
  <li>
  <Link to="/ratings" className={activeLink === 'Ratings' ? 'active' : ''} onClick={() => handleLinkClick('Ratings')}>
  Ratings
  </Link>
  </li>
  </ul>
  </header>

        <GoogleOAuthProvider clientId="617496361150-6iahn4n7q6efjh53ditvi1th0l07gma2.apps.googleusercontent.com">
            <div className="ratings-page">
                {/* User Authentication */}
                {user ? (
                    <div className="user-info">
                        <div className="profile-menu">
                            <img
                                src={user.imageUrl}
                                alt="User"
                                className="user-image"
                                onClick={() => setShowMenu(!showMenu)}
                            />
                            {showMenu && (
                                <div className="menu-dropdown">
                                    <p className="menu-item">{user.name}</p>
                                    <p className="menu-item" onClick={handleLogout}>Logout</p>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
 <div className="google-login">
<GoogleLogin onSuccess={handleLoginSuccess} onError={() => console.log('Login Failed')} />
</div>
)}

 <section>
 {/* Rating Section */}
 {user && !hasRated && (
 <div className="rating-section">
<h2>Rate Us</h2>
<div className="stars">
{renderStars(0)}
</div>
<div className='write'>
<textarea 
placeholder="Leave a comment..." 
 value={comment}
onChange={(e) => setComment(e.target.value)} 
/>
<button onClick={() => submitRating(5)}>Submit Rating</button>
</div>
</div>
)}

{!user && (
<div className="rating-section">
<h2>Your Rating</h2>
<div className="stars">
 {renderStars(0)}  {/* Render other user ratings here */}
</div>
</div>
)}

{hasRated && (
<p>You have already rated us. Thank you!</p>
                    )}
{/* Show message */}
{message && <div className="message" style={{textAlign:'center', color:'red',fontSize:'20px'}}>{message}</div>}
{/* Display Customer Ratings */}
<div className="ratings-grid">
 <h2>Customer Ratings</h2>
 <div className="ratings-list">
  {ratings.map((rating, index) => (
  <div key={index} className="rating-item">
<img src={rating.imageUrl} alt={rating.email} className="rating-avatar" />
<div className="rating-info">
<p className="rating-email">{rating.email}</p>
<div className="stars">
{renderStars(rating.rating)} 
</div>
<p className="rating-comment">{rating.comment}</p>
</div>
</div>
))}
</div>
</div>
</section>
</div>
</GoogleOAuthProvider>
       {/* Footer */}
       <footer className="footer">
<div className="footer-content">
  <div className="footer-column">
<h1>H&O Company</h1>
<div className='logo'>
<img src={logo} alt="Logo" />
</div>
</div>
<div className="footer-column">
<h3>H&O</h3>
<nav className="footer-nav">
<ul>
<li><a href="/"><i className="fas fa-home"></i> HOME</a></li>
<li><a href="/projects"><i className="fas fa-info-circle"></i> Projects</a></li>
<li><a href="/jobs"><i className="fas fa-briefcase"></i> Jobs</a></li>
<li><a href="/about"><i className="about"></i> </a></li>
<li><a href="/contact"><i className="fas fa-envelope"></i>Contact</a></li>
</ul>
</nav>
</div>

<div className="footer-column">
<div id="details">
<h4>Contacts</h4>
<p>
<i className="fas fa-map-marker-alt"></i>
Kajo Karafili Street, BimBashi Building, Floor 4, Tirana, Albania
</p>
<p>
<i className="fas fa-phone"></i>
+355 69 20 73 347
</p>
<p>
<i className="fas fa-envelope"></i>
erahidaj@gmail.com
</p>
</div>
</div>

<div className="footer-column">
<h4>LOCATION</h4>
<p>Floor 4, Tirana, Albania</p>
<div className="map-container">
<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58837.57811877314!2d19.818893593645124!3d41.32752784451191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350a21c31403735%3A0x7c1de0542f6ff5b7!2sTirane%2C%20Albania!5e0!3m2!1sen!2sus!4v1690832994856!5m2!1sen!2sus"
width="100%"
height="300"
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
title="Location Map"
></iframe>
</div>
</div>
</div>
<div className="footer-bottom">
<p>All Rights Reserved by "H&O"
</p>
</div>
</footer>
</div>
);
};

export default RatingsPage;








