import React, { useState } from 'react';
import logo from './images/logo.png'; 
import aboutlogo from './images/aboutlogo.png'; 
import aboutimage1 from './images/aboutimage1.png'; 
import aboutimage2 from './images/aboutimage2.png'; 
import aboutimage3 from './images/aboutimage3.png'; 
import aboutimage4 from './images/aboutimage4.png'; 
import aboutimage5 from './images/aboutimage5.png'; 
import { Link } from 'react-router-dom';
import './index.css'; 

function About() {
  const [activeLink, setActiveLink] = useState('about'); // Added this to manage active links
  const [menuOpen, setMenuOpen] = useState(false); 
  const handleLinkClick = (link) => {
    setActiveLink(link);
    setMenuOpen(false); 
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div>
 {/* Header Section */}
 <header className="header">
  <div className="logo">
  <img src={logo} alt="Logo" />
  </div>

  {/* Hamburger menu icon */}
  <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
  <span></span>
  <span></span>
  <span></span>
  </div>

  {/* Navigation Menu */}
  <ul className={`menu ${menuOpen ? 'open' : ''}`}>
  <li>
  <Link to="/" className={activeLink === 'Home' ? 'active' : ''} onClick={() => handleLinkClick('Home')}>
  Home
  </Link>
  </li>
  <li>
  <Link to="/projects" className={activeLink === 'Projects' ? 'active' : ''} onClick={() => handleLinkClick('Projects')}>
  Projects
  </Link>
  </li>
  <li>
  <Link to="/jobs" className={activeLink === 'Jobs' ? 'active' : ''} onClick={() => handleLinkClick('Jobs')}>
  Jobs
  </Link>
  </li>
  <li>
  <Link to="/contact" className={activeLink === 'Contact' ? 'active' : ''} onClick={() => handleLinkClick('Contact')}>
  Contact
  </Link>
  </li>
  <li>
  <Link to="/about" className={activeLink === 'About' ? 'active' : ''} onClick={() => handleLinkClick('About')}>
   About
  </Link>
  </li>
  <li>
  <Link to="/ratings" className={activeLink === 'Ratings' ? 'active' : ''} onClick={() => handleLinkClick('Ratings')}>
  Ratings
  </Link>
  </li>
  </ul>
  </header>

      {/* About Section */}
        <section className="about-section">
        <div className="about-content">
          <div className="about-image">
            <img src={aboutlogo} alt="H&O Company" />
          </div>
          <div className="about-description">
            <h2>About H&O</h2>
            <p>
              H&O is a construction company with an extensive and excellent reputation, built on the values of excellence and integrity, tested over the years.
            </p>
            <p>
              Over the years, H&O has completed numerous projects in the field of civil and industrial construction, such as residential buildings, industrial and infrastructure construction, social and cultural centers, in collaboration with various prestigious companies in Albania.
            </p>
            <h3>How It Started!</h3>
            <p>
              Founded in 2002 as an individual business focused on the construction and reconstruction of residential buildings and the trade of building materials, H&O has rapidly grown into an ambitious company that offers quality work while being innovative, reliable, and committed to excellence.
            </p>
          </div>
        </div>
      </section>
      <section className="statistics-section">
  <div className="statistics-content">
    <div className="stat-item">
      <h2>128</h2>
      <p>Total Projects</p>
    </div>
    <div className="stat-item">
      <h2>95</h2>
      <p>Completed</p>
    </div>
    <div className="stat-item">
      <h2>33</h2>
      <p>In Progress</p>
    </div>
  </div>
</section>    
{/* Safety and Health Section */}
<section className="safety-health-section">
  <div className="safety-health-content">
    <div className="safety-health-image">
      <img src={aboutimage1} alt="Safety and Health" />
    </div>
    <div className="safety-health-description">
      <h2>Safety and Health</h2>
      <p>
        At H&O, we prioritize the safety and health of our employees, clients, and partners.
        Our commitment to safety is unwavering, ensuring that all projects meet the highest
        safety standards and practices. We believe that a safe environment is essential for
        productivity and success, and we continuously invest in training and equipment to
        protect our teams and our community.
      </p>
      <p>
        Through rigorous protocols and constant vigilance, we aim to maintain a workspace that
        fosters well-being and security. Every project adheres to strict health guidelines to
        safeguard everyone involved, making H&O a leader in responsible and ethical practices.
      </p>
    </div>
  </div>
  <div className="image-row">
        <img src={aboutimage2} alt="Safety precaution example 1" />
        <img src={aboutimage3} alt="Safety precaution example 2" />
        <img src={aboutimage4} alt="Safety precaution example 3" />
        <img src={aboutimage5} alt="Safety precaution example 4" />
      </div>
</section>
 {/* Footer */}
<footer className="footer">
<div className="footer-content">
  <div className="footer-column">
<h1>H&O Company</h1>
<div className='logo'>
<img src={logo} alt="Logo" />
</div>
</div>
<div className="footer-column">
<h3>H&O</h3>
<nav className="footer-nav">
<ul>
<li><a href="/"><i className="fas fa-home"></i> HOME</a></li>
<li><a href="/projects"><i className="fas fa-info-circle"></i> Projects</a></li>
<li><a href="/jobs"><i className="fas fa-briefcase"></i> Jobs</a></li>
<li><a href="/about"><i className="about"></i> </a></li>
<li><a href="/contact"><i className="fas fa-envelope"></i>Contact</a></li>
</ul>
</nav>
</div>

<div className="footer-column">
<div id="details">
<h4>Contacts</h4>
<p>
<i className="fas fa-map-marker-alt"></i>
Kajo Karafili Street, BimBashi Building, Floor 4, Tirana, Albania
</p>
<p>
<i className="fas fa-phone"></i>
+355 69 20 73 347
</p>
<p>
<i className="fas fa-envelope"></i>
erahidaj@gmail.com
</p>
</div>
</div>

<div className="footer-column">
<h4>LOCATION</h4>
<p>Floor 4, Tirana, Albania</p>
<div className="map-container">
<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58837.57811877314!2d19.818893593645124!3d41.32752784451191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350a21c31403735%3A0x7c1de0542f6ff5b7!2sTirane%2C%20Albania!5e0!3m2!1sen!2sus!4v1690832994856!5m2!1sen!2sus"
width="100%"
height="300"
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
title="Location Map"
></iframe>
</div>
</div>
</div>
<div className="footer-bottom">
<p>All Rights Reserved by "H&O"
</p>
</div>
</footer>
    </div>
  );
}

export default About;