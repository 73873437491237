import React, { useState } from 'react'; 
import logo from './images/logo.png'; 
import { Link } from 'react-router-dom';
import './index.css'; 
import tiranaCielo from './images/project-image1.png'; 
import samiFrasheri from './images/project-image2.png';
import lakeView from './images/project-image3.png';
import deltaResidence from './images/project-image4.png';
import katedraljaRingjallja from './images/project-image5.png';
import hotelLuaniArte from './images/project-image6.png'; 
import spaWellness from './images/project-image7.png'; 
import aeroportiNeneTereza from './images/project-image8.png';

const projects = [
    { id: 1, title: 'TIRANA CIELO', description: 'Residential Complex in Mjull Bathore near TEG.', image: tiranaCielo },
    { id: 2, title: 'SAMI FRASHERI', description: 'Renovation near the center of Tirana.', image: samiFrasheri },
    { id: 3, title: 'LAKE VIEW', description: 'High-standard building in Tirana.', image: lakeView },
    { id: 4, title: 'DELTA RESIDENCE', description: 'High-standard building in Tirana.', image: deltaResidence },
    { id: 5, title: 'KATEDRALJA RINGJALLJA E KRISHTIT', description: 'New Orthodox Church in the center of Tirana.', image: katedraljaRingjallja },
    { id: 6, title: 'HOTEL LUANI ARTE', description: 'The 5-story hotel was built in the city of Shkodra with high standards...', image: hotelLuaniArte },
    { id: 7, title: '“LUANI A” SPA & WELLNESS CENTER', description: 'The building is located in the city of Shkodra, with high standards...', image: spaWellness },
    { id: 8, title: 'AEROPORTI NENE TEREZA', description: 'The international airport "Nënë Tereza" is the airport of ...', image: aeroportiNeneTereza }
];

function Projects() {
    // State to manage the menu
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('Projects');

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setMenuOpen(false); 
    };

    return (
        <div>
 {/* Header Section */}
 <header className="header">
  <div className="logo">
  <img src={logo} alt="Logo" />
  </div>

  {/* Hamburger menu icon */}
  <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
  <span></span>
  <span></span>
  <span></span>
  </div>

  {/* Navigation Menu */}
  <ul className={`menu ${menuOpen ? 'open' : ''}`}>
  <li>
  <Link to="/" className={activeLink === 'Home' ? 'active' : ''} onClick={() => handleLinkClick('Home')}>
  Home
  </Link>
  </li>
  <li>
  <Link to="/projects" className={activeLink === 'Projects' ? 'active' : ''} onClick={() => handleLinkClick('Projects')}>
  Projects
  </Link>
  </li>
  <li>
  <Link to="/jobs" className={activeLink === 'Jobs' ? 'active' : ''} onClick={() => handleLinkClick('Jobs')}>
  Jobs
  </Link>
  </li>
  <li>
  <Link to="/contact" className={activeLink === 'Contact' ? 'active' : ''} onClick={() => handleLinkClick('Contact')}>
  Contact
  </Link>
  </li>
  <li>
  <Link to="/about" className={activeLink === 'About' ? 'active' : ''} onClick={() => handleLinkClick('About')}>
   About
  </Link>
  </li>
  <li>
  <Link to="/ratings" className={activeLink === 'Ratings' ? 'active' : ''} onClick={() => handleLinkClick('Ratings')}>
  Ratings
  </Link>
  </li>
  </ul>
  </header>

{/* Content */}
<section className="projects-section">
 <h2>OUR PROJECTS</h2>
 <div className="projects-grid">
 {projects.map((project) => (
 <div key={project.id} className="project-card">
  <img src={project.image} alt={project.title} className="project-image" />
  <h3>{project.title}</h3>
  <p>{project.description}</p>
<button className="see-more-button">See More</button>
 </div>
 ))}
 </div>
 </section>

{/* Footer */}
<footer className="footer">
<div className="footer-content">
  <div className="footer-column">
<h1>H&O Company</h1>
<div className='logo'>
<img src={logo} alt="Logo" />
</div>
</div>
<div className="footer-column">
<h3>H&O</h3>
<nav className="footer-nav">
<ul>
<li><a href="/"><i className="fas fa-home"></i> HOME</a></li>
<li><a href="/projects"><i className="fas fa-info-circle"></i> Projects</a></li>
<li><a href="/jobs"><i className="fas fa-briefcase"></i> Jobs</a></li>
<li><a href="/about"><i className="about"></i> </a></li>
<li><a href="/contact"><i className="fas fa-envelope"></i>Contact</a></li>
</ul>
</nav>
</div>

<div className="footer-column">
<div id="details">
<h4>Contacts</h4>
<p>
<i className="fas fa-map-marker-alt"></i>
Kajo Karafili Street, BimBashi Building, Floor 4, Tirana, Albania
</p>
<p>
<i className="fas fa-phone"></i>
+355 69 20 73 347
</p>
<p>
<i className="fas fa-envelope"></i>
erahidaj@gmail.com
</p>
</div>
</div>

<div className="footer-column">
<h4>LOCATION</h4>
<p>Floor 4, Tirana, Albania</p>
<div className="map-container">
<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58837.57811877314!2d19.818893593645124!3d41.32752784451191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350a21c31403735%3A0x7c1de0542f6ff5b7!2sTirane%2C%20Albania!5e0!3m2!1sen!2sus!4v1690832994856!5m2!1sen!2sus"
width="100%"
height="300"
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
title="Location Map"
></iframe>
</div>
</div>
</div>
<div className="footer-bottom">
<p>All Rights Reserved by "H&O"
</p>
</div>
</footer>
        </div>
    );
}

export default Projects;