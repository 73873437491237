import React, { useState, useEffect } from 'react';
import logo from './images/logo.png'; 
import { Link, useNavigate } from 'react-router-dom';
import './index.css'; 

const jobListings = [
    { id: 1, title: 'Construction Engineer', location: 'Tirana, Albania', description: 'We are looking for a construction engineer to lead new projects and ensure the quality of work.', link: '/jobs/construction-engineer' },
    { id: 2, title: 'Construction Worker', location: 'Tirana, Albania', description: 'We are seeking qualified workers to work on various construction projects.', link: '/jobs/construction-worker' },
    { id: 3, title: 'Construction Foreman', location: 'Tirana, Albania', description: 'We need a construction foreman to lead the construction team and manage materials.', link: '/jobs/construction-foreman' },
    { id: 4, title: 'Construction Archaeologist', location: 'Tirana, Albania', description: 'We are looking for an archaeologist to monitor construction projects and ensure the preservation of cultural heritage.', link: '/jobs/construction-archaeologist' },
    { id: 5, title: 'Architectural Designer', location: 'Tirana, Albania', description: 'We need an architectural designer to draft plans and sketches for construction projects.', link: '/jobs/architectural-designer' },
    { id: 6, title: 'Project Coordinator', location: 'Tirana, Albania', description: 'We are looking for a project coordinator to manage and organize resources for construction projects.', link: '/jobs/project-coordinator' },
    { id: 7, title: 'Structural Engineer', location: 'Tirana, Albania', description: 'We are looking for a structural engineer to develop plans and calculations for various construction structures.', link: '/jobs/structural-engineer' },
    { id: 8, title: 'Installation Foreman', location: 'Tirana, Albania', description: 'We are seeking an installation foreman to manage and carry out installation work on construction projects.', link: '/jobs/installation-foreman' },
];

function Jobs() {
    const [searchTerm, setSearchTerm] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [applications, setApplications] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false); 
    const [activeLink, setActiveLink] = useState('Jobs');
    const navigate = useNavigate(); 

    const filteredJobs = jobListings.filter(job => 
        job.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        if (searchTerm && filteredJobs.length === 0) {
            setShowMessage(true);
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 5000); 
            return () => clearTimeout(timer); 
        }
    }, [searchTerm, filteredJobs]);

    useEffect(() => {
        const savedApplications = JSON.parse(localStorage.getItem('applications')) || [];
        setApplications(savedApplications);
    }, []);

    const handleApplyNow = (jobTitle) => {
        navigate('/application', { state: { jobTitle } }); // Navigate with state
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setMenuOpen(false); 
    };

    return (
        <div>
 {/* Header Section */}
 <header className="header">
  <div className="logo">
  <img src={logo} alt="Logo" />
  </div>

  {/* Hamburger menu icon */}
  <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
  <span></span>
  <span></span>
  <span></span>
  </div>

  {/* Navigation Menu */}
  <ul className={`menu ${menuOpen ? 'open' : ''}`}>
  <li>
  <Link to="/" className={activeLink === 'Home' ? 'active' : ''} onClick={() => handleLinkClick('Home')}>
  Home
  </Link>
  </li>
  <li>
  <Link to="/projects" className={activeLink === 'Projects' ? 'active' : ''} onClick={() => handleLinkClick('Projects')}>
  Projects
  </Link>
  </li>
  <li>
  <Link to="/jobs" className={activeLink === 'Jobs' ? 'active' : ''} onClick={() => handleLinkClick('Jobs')}>
  Jobs
  </Link>
  </li>
  <li>
  <Link to="/contact" className={activeLink === 'Contact' ? 'active' : ''} onClick={() => handleLinkClick('Contact')}>
  Contact
  </Link>
  </li>
  <li>
  <Link to="/about" className={activeLink === 'About' ? 'active' : ''} onClick={() => handleLinkClick('About')}>
   About
  </Link>
  </li>
  <li>
  <Link to="/ratings" className={activeLink === 'Ratings' ? 'active' : ''} onClick={() => handleLinkClick('Ratings')}>
  Ratings
  </Link>
  </li>
  </ul>
  </header>

            
{/* Search Container */}
 <div className="search-container">
 <input
 type="text"
 placeholder="Search for construction jobs..."
 value={searchTerm}
 onChange={(e) => setSearchTerm(e.target.value)}
 className="search-input"
/>
</div>

 {/* No Jobs Message */}
{showMessage && (
 <div className="no-jobs-message">
 <p>There are no jobs matching your search.</p>
 </div>
 )}

 {/* Job Listings */}
 <h2>Available Construction Jobs</h2>
<div className="job-listings">
{filteredJobs.map(job => (
 <div className="job-card" key={job.id}>
 <h3>{job.title}</h3>
 <p>{job.location}</p>
<p>{job.description}</p>
 <div className="button-container">
 <button 
 onClick={() => handleApplyNow(job.title)} 
 className="apply-button"
 >
Apply Now
 </button>
</div>
 </div>
 ))}
</div>

{/* User Applications */}
 <div className="applications-section">
 <h2>Your Applications</h2>
 {applications.length > 0 ? (
 applications.map((app, index) => (
<p key={index}>{app.title}</p>
))
 ) : (
<p>You have not applied to any jobs yet.</p>
)}
 </div>

           
{/* Footer */}
<footer className="footer">
<div className="footer-content">
  <div className="footer-column">
<h1>H&O Company</h1>
<div className='logo'>
<img src={logo} alt="Logo" />
</div>
</div>
<div className="footer-column">
<h3>H&O</h3>
<nav className="footer-nav">
<ul>
<li><a href="/"><i className="fas fa-home"></i> HOME</a></li>
<li><a href="/projects"><i className="fas fa-info-circle"></i> Projects</a></li>
<li><a href="/jobs"><i className="fas fa-briefcase"></i> Jobs</a></li>
<li><a href="/about"><i className="about"></i> </a></li>
<li><a href="/contact"><i className="fas fa-envelope"></i>Contact</a></li>
</ul>
</nav>
</div>

<div className="footer-column">
<div id="details">
<h4>Contacts</h4>
<p>
<i className="fas fa-map-marker-alt"></i>
Kajo Karafili Street, BimBashi Building, Floor 4, Tirana, Albania
</p>
<p>
<i className="fas fa-phone"></i>
+355 69 20 73 347
</p>
<p>
<i className="fas fa-envelope"></i>
erahidaj@gmail.com
</p>
</div>
</div>

<div className="footer-column">
<h4>LOCATION</h4>
<p>Floor 4, Tirana, Albania</p>
<div className="map-container">
<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58837.57811877314!2d19.818893593645124!3d41.32752784451191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350a21c31403735%3A0x7c1de0542f6ff5b7!2sTirane%2C%20Albania!5e0!3m2!1sen!2sus!4v1690832994856!5m2!1sen!2sus"
width="100%"
height="300"
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
title="Location Map"
></iframe>
</div>
</div>
</div>
<div className="footer-bottom">
<p>All Rights Reserved by "H&O"
</p>
</div>
</footer>      
</div>
    );
}

export default Jobs;