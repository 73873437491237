import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './index.css';
import logo from './images/logo.png';

function Application() {
  const location = useLocation();
  const jobTitle = location.state?.jobTitle || "Job Title";
  console.log('Location State:', location.state);
  console.log('Job Title:', jobTitle);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [resume, setResume] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');


    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        setError('');

        const formData = new FormData();
        
        formData.append('name', name);
        formData.append('email', email);
        formData.append('resume', resume);
        formData.append('jobTitle', jobTitle);

        fetch('https://hocompany.website/api/save-application.php', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            console.log(data); 
            setMessage(data.message);
            setModalVisible(true);
        })
        .catch(error => console.error('Error:', error));
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setMenuOpen(false); 
    };

    return (
        <div>
 {/* Header Section */}
 <header className="header">
  <div className="logo">
  <img src={logo} alt="Logo" />
  </div>

  {/* Hamburger menu icon */}
  <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
  <span></span>
  <span></span>
  <span></span>
  </div>

  {/* Navigation Menu */}
  <ul className={`menu ${menuOpen ? 'open' : ''}`}>
  <li>
  <Link to="/" className={activeLink === 'Home' ? 'active' : ''} onClick={() => handleLinkClick('Home')}>
  Home
  </Link>
  </li>
  <li>
  <Link to="/projects" className={activeLink === 'Projects' ? 'active' : ''} onClick={() => handleLinkClick('Projects')}>
  Projects
  </Link>
  </li>
  <li>
  <Link to="/jobs" className={activeLink === 'Jobs' ? 'active' : ''} onClick={() => handleLinkClick('Jobs')}>
  Jobs
  </Link>
  </li>
  <li>
  <Link to="/contact" className={activeLink === 'Contact' ? 'active' : ''} onClick={() => handleLinkClick('Contact')}>
  Contact
  </Link>
  </li>
  <li>
  <Link to="/about" className={activeLink === 'About' ? 'active' : ''} onClick={() => handleLinkClick('About')}>
   About
  </Link>
  </li>
  <li>
  <Link to="/ratings" className={activeLink === 'Ratings' ? 'active' : ''} onClick={() => handleLinkClick('Ratings')}>
  Ratings
  </Link>
  </li>
  </ul>
  </header>

            <div className="application-form">
                <h2>Applying for: <strong>{jobTitle}</strong></h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {error && <div className="error">{error}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="resume">Resume:</label>
                        <input
                            type="file"
                            id="resume"
                            accept=".pdf"
                            onChange={(e) => setResume(e.target.files[0])}
                            required
                        />
                    </div>
                    <button type="submit">Submit Application</button>
                </form>
            </div>

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setModalVisible(false)}>&times;</span>
                        <h2>Form Submitted!</h2>
                        <i className="fas fa-check-circle" style={{ color: 'green', fontSize: '35px' }}></i>
                        <p>{message}</p>
                    </div>
                </div>
            )}

            <Link to="/applications">See Applications</Link>

            <footer className="footer">
<div className="footer-content">
  <div className="footer-column">
<h1>H&O Company</h1>
<div className='logo'>
<img src={logo} alt="Logo" />
</div>
</div>
<div className="footer-column">
<h3>H&O</h3>
<nav className="footer-nav">
<ul>
<li><a href="/"><i className="fas fa-home"></i> HOME</a></li>
<li><a href="/projects"><i className="fas fa-info-circle"></i> Projects</a></li>
<li><a href="/jobs"><i className="fas fa-briefcase"></i> Jobs</a></li>
<li><a href="/about"><i className="about"></i> </a></li>
<li><a href="/contact"><i className="fas fa-envelope"></i>Contact</a></li>
</ul>
</nav>
</div>

<div className="footer-column">
<div id="details">
<h4>Contacts</h4>
<p>
<i className="fas fa-map-marker-alt"></i>
Kajo Karafili Street, BimBashi Building, Floor 4, Tirana, Albania
</p>
<p>
<i className="fas fa-phone"></i>
+355 69 20 73 347
</p>
<p>
<i className="fas fa-envelope"></i>
erahidaj@gmail.com
</p>
</div>
</div>

<div className="footer-column">
<h4>LOCATION</h4>
<p>Floor 4, Tirana, Albania</p>
<div className="map-container">
<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58837.57811877314!2d19.818893593645124!3d41.32752784451191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350a21c31403735%3A0x7c1de0542f6ff5b7!2sTirane%2C%20Albania!5e0!3m2!1sen!2sus!4v1690832994856!5m2!1sen!2sus"
width="100%"
height="300"
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
title="Location Map"
></iframe>
</div>
</div>
</div>
<div className="footer-bottom">
<p>All Rights Reserved by "H&O"
</p>
</div>
</footer>
</div>
    );
}

export default Application;