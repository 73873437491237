import React, { useState } from 'react';
import logo from './images/logo.png';
import { Link } from 'react-router-dom';
import './index.css';

function Contact() {
  const [formStatus, setFormStatus] = useState(null);
  const [activeLink, setActiveLink] = useState('contact'); // Added this to manage active links
  const [menuOpen, setMenuOpen] = useState(false); // Initialize menuOpen state

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setMenuOpen(false); 
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const form = event.target;
    const formData = new FormData(form);
    
    try {
      const response = await fetch('https://formspree.io/f/manyekal', {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        setFormStatus('Thank you! We will get back to you shortly.');
        form.reset(); 
      } else {
        setFormStatus('Oops! There was an issue sending the form.');
      }
    } catch (error) {
      setFormStatus('Something went wrong. Please try again.');
    }
  };

  return (
    <div>
 {/* Header Section */}
 <header className="header">
  <div className="logo">
  <img src={logo} alt="Logo" />
  </div>

  {/* Hamburger menu icon */}
  <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
  <span></span>
  <span></span>
  <span></span>
  </div>

  {/* Navigation Menu */}
  <ul className={`menu ${menuOpen ? 'open' : ''}`}>
  <li>
  <Link to="/" className={activeLink === 'Home' ? 'active' : ''} onClick={() => handleLinkClick('Home')}>
  Home
  </Link>
  </li>
  <li>
  <Link to="/projects" className={activeLink === 'Projects' ? 'active' : ''} onClick={() => handleLinkClick('Projects')}>
  Projects
  </Link>
  </li>
  <li>
  <Link to="/jobs" className={activeLink === 'Jobs' ? 'active' : ''} onClick={() => handleLinkClick('Jobs')}>
  Jobs
  </Link>
  </li>
  <li>
  <Link to="/contact" className={activeLink === 'Contact' ? 'active' : ''} onClick={() => handleLinkClick('Contact')}>
  Contact
  </Link>
  </li>
  <li>
  <Link to="/about" className={activeLink === 'About' ? 'active' : ''} onClick={() => handleLinkClick('About')}>
   About
  </Link>
  </li>
  <li>
  <Link to="/ratings" className={activeLink === 'Ratings' ? 'active' : ''} onClick={() => handleLinkClick('Ratings')}>
  Ratings
  </Link>
  </li>
  </ul>
  </header>

  {/* Contact Section */}
  <section id="gmail-details">
  <p><i className="fas fa-envelope"></i> Email:erahidaj@gmail.com</p>
  </section>
  <section className="contact-section">
         
  <div className="contact-icon">
  <i className="fas fa-phone-alt fa-3x" style={{ color: '#14461f', marginBottom: '20px', fontSize:'35px' }}></i>
  <h3>Contact us directly at:</h3>
  <p>+355 69 20 73 347</p>
</div>
         
<div className="contact-form-container">
<h1>Contact Us</h1>
<form onSubmit={handleSubmit}>
<div className="form-group">
<label htmlFor="name">
<i className="fas fa-user"></i> Name:
</label>
<input type="text" id="name" name="name" required />
</div>

  <div className="form-group">
  <label htmlFor="email">
 <i className="fas fa-envelope"></i> Email:
  </label>
  <input type="email" id="email" name="email" required />
  </div>

  <div className="form-group">
  <label htmlFor="message">
  <i className="fas fa-comment-dots"></i> Message:
  </label>
  <textarea id="message" name="message" rows="4" required></textarea>
  </div>

  <button type="submit">
  <i className="fas fa-paper-plane"></i> Send Message
  </button>
  </form>
  {formStatus && <p className="form-status">{formStatus}</p>}
</div>
</section>

{/* Footer */}
<footer className="footer">
<div className="footer-content">
  <div className="footer-column">
<h1>H&O Company</h1>
<div className='logo'>
<img src={logo} alt="Logo" />
</div>
</div>
<div className="footer-column">
<h3>H&O</h3>
<nav className="footer-nav">
<ul>
<li><a href="/"><i className="fas fa-home"></i> HOME</a></li>
<li><a href="/projects"><i className="fas fa-info-circle"></i> Projects</a></li>
<li><a href="/jobs"><i className="fas fa-briefcase"></i> Jobs</a></li>
<li><a href="/about"><i className="about"></i> </a></li>
<li><a href="/contact"><i className="fas fa-envelope"></i>Contact</a></li>
</ul>
</nav>
</div>

<div className="footer-column">
<div id="details">
<h4>Contacts</h4>
<p>
<i className="fas fa-map-marker-alt"></i>
Kajo Karafili Street, BimBashi Building, Floor 4, Tirana, Albania
</p>
<p>
<i className="fas fa-phone"></i>
+355 69 20 73 347
</p>
<p>
<i className="fas fa-envelope"></i>
erahidaj@gmail.com
</p>
</div>
</div>

<div className="footer-column">
<h4>LOCATION</h4>
<p>Floor 4, Tirana, Albania</p>
<div className="map-container">
<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58837.57811877314!2d19.818893593645124!3d41.32752784451191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350a21c31403735%3A0x7c1de0542f6ff5b7!2sTirane%2C%20Albania!5e0!3m2!1sen!2sus!4v1690832994856!5m2!1sen!2sus"
width="100%"
height="300"
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
title="Location Map"
></iframe>
</div>
</div>
</div>
<div className="footer-bottom">
<p>All Rights Reserved by "H&O"
</p>
</div>
</footer>
    </div>
  );
}

export default Contact;